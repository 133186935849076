import React from "react";

const IconSign = () => {
  return (
    <>
      <svg
        width="24"
        height="25"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 25"
      >
        <defs />
        <g clipPath="url(#clip0)">
          <path
            fill="currentColor"
            d="M21.781 14.375l-2-2.5A1.0002 1.0002 0 0019 11.5h-6v-2h6c1.103 0 2-.897 2-2v-3c0-1.103-.897-2-2-2H5a1 1 0 00-.781.375l-2 2.5a1.001 1.001 0 000 1.25l2 2.5A1 1 0 005 9.5h6v2H5c-1.103 0-2 .897-2 2v3c0 1.103.897 2 2 2h6v4h2v-4h6a.9997.9997 0 00.781-.375l2-2.5a1.001 1.001 0 000-1.25zM4.281 6L5.48 4.5H19l.002 3H5.48L4.281 6zM18.52 16.5H5v-3h13.52l1.2 1.5-1.2 1.5z"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <path fill="#fff" d="M0 0h24v24H0z" transform="translate(0 .5)" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default IconSign;
