import React, { useContext } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import MainMenu from "./MainMenu";
import HamburgerButton from "./HamburgerButton";
import { color50, clearBg, minDesktop } from "../styles";
import { Context } from "../state";
import { useMatch } from "@reach/router";

const Header = styled.header`
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 10;
  background: ${clearBg};
  padding: 16px 0;

  @media (${minDesktop}) {
    padding: 0;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LogoWrapper = styled.div`
  flex: 0 0 192px;
`;

const Logo = styled.a`
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 160%;
  display: flex;
  align-items: center;
  letter-spacing: -0.017em;
  justify-content: center;
  color: ${color50};
  cursor: pointer;
  text-decoration: none;

  img {
    max-width: 100%;
    display: block;
    height: auto;
    margin: 0 6px 0 0;
  }

  &:visited {
    color: ${color50};
  }

  &:hover,
  &:active {
    opacity: 0.8;
  }
`;

const ResponsiveWrapper = styled.div`
  display: inline-flex;
  justify-content: flex-end;

  @media (${minDesktop}) {
    display: none;
  }
`;

const MenuWrapper = styled.div`
  display: none;

  @media (${minDesktop}) {
    display: inline-block;
  }
`;

const Container = styled.div``;

const AppHeader = () => {
  const [state] = useContext(Context);
  const { pageType } = state;

  const thoughtsPost = useMatch("/thoughts/:id");
  const thoughtsArchive = useMatch("/thoughts");
  const journeyPost = useMatch("/journey/:id");
  const journeyArchive = useMatch("/journey");
  const aboutPage = useMatch("/about");
  const photoPost = useMatch("/photos/:id");
  const photoArchive = useMatch("/photos");
  let current;

  if (thoughtsPost || thoughtsArchive) {
    current = "thoughts";
  } else if (journeyPost || journeyArchive) {
    current = "journey";
  } else if (photoPost || photoArchive) {
    current = "photos";
  } else if (aboutPage) {
    current = "about";
  }

  return (
    <Header role="navigation" aria-label="main-navigation">
      <Container
        className={`${
          !current && current === "about" ? "small-" : ""
        }container`}
      >
        <Wrapper>
          <LogoWrapper>
            <Logo as={Link} to="/" title="Darren Rowse">
              <img
                src="/assets/logo-darren-rowse.svg"
                draggable={false}
                width={54}
                height={15}
                alt="Darren Rowse"
              />
              Darren Rowse
            </Logo>
          </LogoWrapper>
          <ResponsiveWrapper>
            <HamburgerButton />
          </ResponsiveWrapper>
          <MenuWrapper>
            <MainMenu small={pageType !== "index"} current={current} />
          </MenuWrapper>
        </Wrapper>
      </Container>
    </Header>
  );
};

export default AppHeader;
