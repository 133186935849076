import React, { useContext } from "react";
import styled from "styled-components";
import MainMenu from "./MainMenu";
import {
  clearBg,
  color30,
  minDesktop,
  offCanvasSize,
  offCanvasTime
} from "../styles/";
import { Context } from "../state";

const Close = styled.div`
  content: "";
  position: absolute;
  width: 100vw;
  z-index: -1;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  display: none;
`;

const Wrapper = styled.div`
  @media (${minDesktop}) {
    display: none;
  }

  background: ${clearBg};
  position: fixed;
  top: 0;
  bottom: 0;
  left: -${offCanvasSize};
  z-index: 15;
  width: ${offCanvasSize};
  transition: ${offCanvasTime} transform;

  &:after {
    content: "";
    display: block;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: 0;
    box-shadow: 1px -1px 9px 3px ${color30};
    transition: 0.2s opacity;
    opacity: 1;
  }

  &.active {
    transform: translateX(${offCanvasSize});

    ${Close} {
      pointer-events: all;
      display: block;
    }

    &:after {
      opacity: 1;
    }
  }
`;

const MenuWrapper = styled.div`
  position: relative;
  z-index: 2;
  height: 100%;
`;

const OffCanvas = () => {
  const [state, dispatch] = useContext(Context);
  const { canvas } = state;

  return (
    <Wrapper className={canvas ? "active" : ""}>
      <Close
        onClick={() => {
          dispatch({ type: "TOGGLE_CANVAS" });
        }}
      />
      <MenuWrapper>
        <MainMenu fullWidth />
      </MenuWrapper>
    </Wrapper>
  );
};

export default OffCanvas;
