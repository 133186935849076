import React, { useContext } from "react";
import Footer from "../components/Footer";
import AppHeader from "./AppHeader";
import SEO from "./SEO";
import { Context } from "../state/";
import OffCanvas from "./OffCanvas";
import styled from "styled-components";
import "../styles/application.scss";
import { mainBg, offCanvasSize, offCanvasTime } from "../styles";

const Canvas = styled.div`
  transition: ${offCanvasTime} transform;

  &.active {
    transform: translateX(${offCanvasSize});
  }
`;

const Main = styled.main`
  background-color: ${mainBg};
`;

const TemplateWrapper = ({ children }) => {
  const [state] = useContext(Context);
  const { canvas } = state;

  return (
    <>
      <SEO />
      <OffCanvas />
      <Canvas className={canvas ? "active" : ""}>
        <AppHeader />
        <Main>{children}</Main>
        <Footer />
      </Canvas>
    </>
  );
};

export default TemplateWrapper;
