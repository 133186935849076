import React, { useContext } from "react";
import styled from "styled-components";
import { colorMain } from "../styles";
import { Context } from "../state";

const IconBar = styled.span`
  display: block;
  width: 15px;
  height: 2px;
  background-color: ${colorMain};

  &:nth-child(2) {
    margin: 4px 0;
  }
`;

const Button = styled.a`
  display: inline-block;
  font-size: 20px;
  padding: 6px;
  align-content: center;
  cursor: pointer;
  border-radius: 6px;
`;

const HamburgerButton = () => {
  const [, dispatch] = useContext(Context);

  const toggle = () => {
    dispatch({ type: "TOGGLE_CANVAS" });
  };

  const handleKeyDown = (ev) => {
    if (ev.keyCode === 13) {
      toggle();
    }
  };
  return (
    <Button
      role="button"
      tabIndex="0"
      onKeyDown={(ev) => handleKeyDown(ev)}
      onClick={() => toggle()}
    >
      <IconBar />
      <IconBar />
      <IconBar />
    </Button>
  );
};

export default HamburgerButton;
