import React from "react";
import styled from "styled-components";
import { graphql, Link, useStaticQuery } from "gatsby";
import IconBell from "./Icons/IconBell";
import IconPerson from "./Icons/IconPerson";
import IconSign from "./Icons/IconSign";
import IconFlower from "./Icons/IconFlower";
import { color30, colorMain, minDesktop } from "../styles";
import IconCamera from "./Icons/IconCamera";

const Item = styled.a`
  display: inline-flex;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 171%;
  align-items: center;
  justify-content: center;
  letter-spacing: -0.006em;
  transition: 0.2s opacity;
  color: ${colorMain};
  padding: 17px 18px;
  cursor: pointer;
  text-decoration: none;

  &:hover,
  &:active {
    opacity: 0.6;
  }
`;

const Icon = styled.span`
  margin-right: 8px;
`;

const Text = styled.span`
  text-decoration: none;

  &:visited {
    color: ${colorMain};
  }
`;

const Wrapper = styled.nav`
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  &.full {
    width: 100%;
    justify-content: center;

    ${Item} {
      width: 100%;
      border-bottom: 1px solid ${color30};
    }
  }

  &.small {
    ${Text} {
      display: none;
    }

    ${Item} {
      ${Icon} {
        margin-right: 0;
      }

      &.current {
        ${Icon} {
          margin-right: 8px;
        }

        ${Text} {
          display: inline;
        }
      }
    }
  }

  .current {
    color: #f1ca82;

    ${Icon} {
      color: #f1ca82;
    }
  }

  @media (${minDesktop}) {
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-end;
  }
`;

const query = graphql`
  query hasPhotos {
    photos:allMarkdownRemark(
      limit: 1
      filter: {
        frontmatter: {
          draft: { eq: false }
          templateKey: { eq: "photo-post" }
        }
      }
    ) {
      edges {
        node {
          id
        }
      }
    }
  }
`;


const MainMenu = ({ noIcons, fullWidth, small, current }) => {
  let mainClass = fullWidth ? "full" : "";
  mainClass += small ? " small" : "";

  const { photos } = useStaticQuery(query);

  const isCurrent = (key) => {
    return current === key ? "current" : "";
  };
  return (
    <>
      <Wrapper className={mainClass}>
        <Item
          as={Link}
          to="/about"
          title="About"
          className={isCurrent("about")}
        >
          {!noIcons && (
            <Icon>
              <IconPerson />
            </Icon>
          )}
          <Text>About</Text>
        </Item>

        <Item
          as={Link}
          to="/thoughts"
          title="My Thoughts"
          className={isCurrent("thoughts")}
        >
          {!noIcons && (
            <Icon>
              <IconFlower />
            </Icon>
          )}
          <Text>My Thoughts</Text>
        </Item>

        <Item
          as={Link}
          to="/journey"
          title="My Journey"
          className={isCurrent("journey")}
        >
          {!noIcons && (
            <Icon>
              <IconSign />
            </Icon>
          )}
          <Text>My Journey</Text>
        </Item>

        {photos && photos.hasOwnProperty("edges") && photos.edges.length > 0 && <Item
          as={Link}
          to="/photos"
          title="My Photos"
          className={isCurrent("photos")}
        >
          {!noIcons && (
            <Icon>
              <IconCamera />
            </Icon>
          )}
          <Text>My Photos</Text>
        </Item>}

        <Item title="Subscribe" data-formkit-toggle="980bde946d" href="#">
          {!noIcons && (
            <Icon>
              <IconBell />
            </Icon>
          )}
          <Text>Subscribe</Text>
        </Item>
      </Wrapper>
    </>
  );
};

export default MainMenu;
