export const colorMain = "#333333";
export const colorLink = "#4C8EB3";
export const colorLinkBg = "#ffffff";
export const colorButton = "#F1CA82";
export const color50 = "rgba(32, 32, 32, 0.5)";
export const color30 = "rgba(32, 32, 32, 0.3)";
export const clearBg = "#FFFDF9";
export const mainBg = "#FFF9EF";

export const footerBg = "#F7EEDF";
export const minDesktop = "min-width: 900px";
export const maxMobile = "max-width: 560px";

export const fontFamily = "'Inter', sans-serif;";

export const offCanvasSize = "250px";
export const offCanvasTime = "0.4s";
