import React from "react";
import styled from "styled-components";
import MainMenu from "./MainMenu";
import { color50, footerBg, minDesktop } from "../styles";

const Wrapper = styled.footer`
  background: ${footerBg};
  padding: 28px 0 28px;
`;

const Container = styled.div`
  text-align: center;

  @media (${minDesktop}) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;
  }
`;

const Copy = styled.p`
  @media (${minDesktop}) {
    margin: 0;
  }

  margin: 0 0 24px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 160%;
  letter-spacing: -0.017em;
  color: ${color50};

  img {
    margin-right: 7px;
  }
`;

const Footer = class extends React.Component {
  render() {
    return (
      <Wrapper>
        <Container className="container">
          <Copy>
            <img
              src="/assets/icon-copy.svg"
              alt="Copyright"
              draggable={false}
              width={24}
              height={25}
            />
            Darren Rowse
          </Copy>

          <MainMenu noIcons={true} />
        </Container>
      </Wrapper>
    );
  }
};

export default Footer;
