import React from "react";

const IconCamera = () => {
  return (
    <svg width={23} height={23} aria-hidden="true" role="image" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <path fill="currentColor"
            d="M256 408a120.1 120.1 0 010-240 120.1 120.1 0 010 240zm0-192a72 72 0 10.2 144.2A72 72 0 00256 216zm-24 72a24 24 0 0124-24c8.8 0 16-7.2 16-16s-7.2-16-16-16a56 56 0 00-56 56c0 8.8 7.2 16 16 16s16-7.2 16-16zm110.7-145H464v288H48V143h121.3l24-64h125.5l23.9 64zM324.3 31h-131a48 48 0 00-44.9 31.1L136 95H48a48 48 0 00-48 48v288a48 48 0 0048 48h416a48 48 0 0048-48V143a48 48 0 00-48-48h-88l-14.3-38a39.8 39.8 0 00-37.4-26z" />
    </svg>
  );
};

export default IconCamera;
