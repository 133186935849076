import React from "react";

const IconFlower = () => {
  return (
    <>
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0)">
          <path
            fill="currentColor"
            d="M20.787 9.52301C20.662 9.55 18.984 9.941 16.834 11.297C16.511 9.73 15.555 6.79601 12.726 3.81201L12 3.04601L11.274 3.81301C8.435 6.80801 7.483 9.75001 7.163 11.327C5.005 9.94801 3.34 9.55201 3.218 9.52401L2 9.25201V10.5C2 17.79 5.925 22.5 12 22.5C17.981 22.5 22 17.678 22 10.5V9.25801L20.787 9.52301ZM8.999 12.538C9.001 12.505 9.151 9.43801 12 6.00601C14.814 9.40601 14.999 12.5 15 12.5V12.625C13.8786 13.5569 12.8685 14.6153 11.99 15.779C11.109 14.6358 10.1061 13.592 8.999 12.666V12.538ZM12 20.5C6.684 20.5 4.451 16.304 4.063 11.936C5.718 12.654 8.679 14.362 11.17 18.059L12.011 19.308L12.836 18.048C15.262 14.34 18.261 12.637 19.932 11.926C19.534 16.154 17.304 20.5 12 20.5Z"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect
              width="24"
              height="25"
              fill="white"
              transform="translate(0 0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default IconFlower;
