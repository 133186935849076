import React from "react";

const IconPerson = () => {
  return (
    <>
      <svg
        width="24"
        height="25"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 25"
      >
        <defs />
        <g clipPath="url(#clip0)">
          <path
            fill="currentColor"
            d="M12 2.5a5 5 0 10.01 10.01A5 5 0 0012 2.5zm0 8a3 3 0 110-6 3 3 0 010 6zm9 11v-1a7 7 0 00-7-7h-4a7 7 0 00-7 7v1h2v-1a5 5 0 015-5h4a5 5 0 015 5v1h2z"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <path fill="#fff" d="M0 0h24v24H0z" transform="translate(0 .5)" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default IconPerson;
