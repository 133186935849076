import React from "react";

const IconBell = () => {
  return (
    <>
      <svg
        width="24"
        height="25"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 25"
      >
        <defs />
        <g clipPath="url(#clip0)">
          <path
            fill="currentColor"
            d="M19 14.09V10.5a7 7 0 00-5.14-6.74 1.99 1.99 0 00-3.71 0A7 7 0 005 10.5v3.59l-1.7 1.7a1 1 0 00-.3.71v2a1 1 0 001 1h16a1 1 0 001-1v-2a1 1 0 00-.3-.7L19 14.08zm0 3.41H5v-.59l1.7-1.7a1 1 0 00.3-.71v-4a5 5 0 0110 0v4c0 .27.1.52.3.7l1.7 1.71v.59zm-7 5a2.98 2.98 0 002.82-2H9.18a2.98 2.98 0 002.82 2z"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <path fill="#fff" d="M0 0h24v24H0z" transform="translate(0 .5)" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default IconBell;
